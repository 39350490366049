import SVG from "components/elements/SVG";
import { BlockerContext } from "components/utils/BlockerContext";
import ImageDataStore from "datastore/ImageDataStore";
import { useContext } from "react";

function PaymentHeader(props: { itemId: string }) {
  const { itemId } = props;
  const { allData } = useContext(BlockerContext);
  const currentItem = allData.filter((item: any) => item.id === itemId)[0];
  return (
    <>
      <div className="hp-blockedModal-popup__header">
        {currentItem ? (<>
          {currentItem.purchaseImgKey ? (<>
            {currentItem.purchaseImgKey.indexOf(".svg") > -1 ? (
              <SVG src={currentItem.purchaseImgKey} className="hp-blockedModal-popup__header-icon" />
            ) : (
              <img src={ImageDataStore.getUrl(currentItem.purchaseImgKey)} className="hp-blockedModal-popup__header-icon" alt={currentItem.purchaseTitle} />
            )}
          </>) : (
            <img src={ImageDataStore.getUrl(currentItem.imageChapter)} className="hp-blockedModal-popup__header-icon" alt={currentItem.purchaseTitle} />
          )}
          <div className="hp-blockedModal-popup__header-text">
            <div className="hp-blockedModal-popup__header-title">
              <b>{currentItem.purchaseTitle}</b>
            </div>
            <div className="hp-blockedModal-popup__header-desc">
              {currentItem.purchaseDesc}
            </div>
            <div className="hp-blockedModal-popup__header-content">
              <b>Contenu : </b>{currentItem.purchaseContent}
            </div>
            <div className="hp-blockedModal-popup__header-state">
              <b>État : </b>{currentItem?.status}
            </div>
          </div>
        </>) : (<>Loading</>)}
      </div>
    </>
  );
}

export default PaymentHeader;
