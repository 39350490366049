import { createContext, useRef, useState } from "react";

export const BlockerContext = createContext<any>("");

export enum steps {
  SUBSCRIPTION,
  RELOAD,
  ONESHOT_PAYMENT,
  MEMBER,
  FINISHED,
  SIGNUP,
  EDITPROFIL,
  ORDERS,
  SIGNIN,
  GIFT,
  SUBSCRIPTION_PAYMENT,
  SHARE,
  LOADING,
  WHO,
  WHAT,
  SUPPORTUS,
  CONTACTUS
}

const BlockerContextProvider = (props: any) => {
  const [block, setBlock] = useState<boolean>();
  const [itemId, setItemId] = useState<string>();
  const [itemChapterId, setItemChapterId] = useState<string>();
  const [currentVideoData, setCurrentVideoData] = useState<any>();
  const [appType, setAppType] = useState<string>();
  const [allData, setAllData] = useState<any[]>([]);
  const [unblockStep, setUnblockStep] = useState<steps>(props.initialSteps || steps.SUBSCRIPTION);
  const nextStep = useRef<steps | undefined>(props.nextSteps);

  return (
    <BlockerContext.Provider value={{ currentVideoData, setCurrentVideoData, appType, setAppType, itemChapterId, setItemChapterId, itemId, setItemId, allData, setAllData, block, setBlock, unblockStep, setUnblockStep, nextStep }} >
      {props.children}
    </BlockerContext.Provider>
  );
};

export default BlockerContextProvider;
