import "./FreemiumAvailable.scss";
import { ReactComponent as SubscriptionBtn } from "assets/v2/subscription-btn.svg";
import { routeToPlatformHomePage } from "components/utils/InternalRouter";

const FreemiumAvailable = () => {
  return (<>
    <div className="ms-home-freemium-available">
      <div className="ms-home-freemium-available-title">Disponible gratuitement :</div>
      <div className="ms-home-freemium-available-text">LES 3 PREMIERS ÉPISODES</div>
      <a href={`${routeToPlatformHomePage()}/subscribe`} className='hp-freemium-banner-sub-btn'><SubscriptionBtn /></a>
    </div>
  </>);
};

export default FreemiumAvailable;
