import HomepageV2 from "./V2/HomepageV2";
import HomeLeftArea from "./layout/HomeLeftArea";
import LayoutV2 from "./layout/LayoutV2";
import { useContext } from "react";
import { UserContext } from "./utils/UserContext";
import HomeLeftAreaV3 from "./elements/common/freemium/HomeLeftArea";

const Homepage = (props: any) => {
	const { freemiumEnabled } = useContext(UserContext);
	const { categoryName } = props;

	return (
		<LayoutV2 leftArea={freemiumEnabled ? <HomeLeftAreaV3 categoryName={categoryName}/> : <HomeLeftArea categoryName={categoryName} />} isAuthenticated={true} className={'ff-homepage ' + categoryName} >
			<HomepageV2 {...props} categoryName={categoryName} />{" "}
		</LayoutV2>
	);
};
export default Homepage;
