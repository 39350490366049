import React, { useEffect, useState } from "react";
import ImageDataStore from "../../datastore/ImageDataStore";
import { ClockLoader as Loader } from "react-spinners";
import "../../common.scss"

const ImageComponent = (props: {
	children: any;
	imageKey: any;
	onClick?: any;
	alt: string | undefined;
	width: string | number | undefined;
	className: string | undefined;
	imageSrc?: string;
}) => {
	const [fileURL, setFileURL] = useState("");
	const [loading, setLoading] = useState<boolean>(false);

	const { children } = props;

	useEffect(() => {
		setLoading(true);
		if (props.imageKey) {
			ImageDataStore.getUrlImage(
				props.imageKey,
				setFileURL
			);
		} else if (props.imageSrc) {
			setFileURL(props.imageSrc);
		} else {
			setFileURL("");
		}
	}, [props.imageKey, props.imageSrc]);

	return (
		<>
			{fileURL && (
				<>
					{loading ? (
						<div className="loader-video loader-video-thumbnail">
							<Loader />
						</div>
					) : (
						""
					)}
					<img
						src={fileURL}
						alt={props.alt}
						width={props.width}
						onClick={props.onClick}
						className={props.className}
						style={{display: loading ? 'none' : 'block'}}
						onLoad={() => setLoading(false)}
						onError={() => setLoading(false)}
					/>
				</>
			)}
			{!fileURL && <>{children}</>}
		</>
	);
};

export default function Image(props: any) {
	return <ImageComponent {...props} />;
}
