import "./ChapterLeftArea.scss";
import React, { useEffect, useState } from "react";
import BaseDS from "datastore/BaseDS";
import { TVideo } from "types";
import ImageComponent from "components/elements/ImageComponent";
import { AiFillHome } from "react-icons/ai";
import SVG from "components/elements/SVG";
import { Link } from "react-router-dom";
import HooPowAppMenuV3 from "components/elements/common/internalrouter/HooPowAppMenuV3";

const ChapterLeftArea = (props: any) => {
	const currentVideoId = props.match.params.videoId;
	const [video, setVideo] = useState<TVideo>();

	useEffect(() => {
		BaseDS.getFullVideoById(currentVideoId).then((data: any) => {
			setVideo(data);
		});
	}, [currentVideoId]);


	return (
		<>
			<div className="chapter-left">
				<div className="chapter-left__poster">
					{video?.posterChapter && (
						<ImageComponent imageKey={video?.posterChapter} />
					)}
				</div>
				{video && (
					<div className="chapter-left__infos">
						<SVG
							className="chapter-left__infos-thumbnail"
							src={video?.thumbnailsImage}
						/>
						<div>
							<h2>{video?.name}</h2>
							<div style={{ display: "flex" }}>
								<p className={video?.status === 'complet' ? "status complete" : "status ongoing "}>
									{video?.status}
								</p>
								<span className="time">{video?.durationTotal}</span>
							</div>
						</div>
					</div>
				)}
			</div>
			<div className="chapter-buttons">
				<Link to={`/${props.categoryName}`} className="chapter-buttons__back-home">
					<AiFillHome className="back-home-icon" />
					<span>Accueil</span>
				</Link>
			</div>
			<HooPowAppMenuV3 />
		</>
	);
};

export default ChapterLeftArea;
