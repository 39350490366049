import "./VideoLeftArea.scss";
import { useContext, useState } from "react";
import { ReactComponent as Arrow } from "../../assets/v2/arrow.svg";
import { ReactComponent as IconFullScreen } from "../../assets/v2/icon_fullScreen.svg";
import { ReactComponent as Circle } from "../../assets/v2/circle.svg";
import { ReactComponent as Toggle } from "../../assets/v2/toggle.svg";
import ImageComponent from "components/elements/ImageComponent";
import { IoMenu } from "react-icons/io5";
import { Link } from "react-router-dom";
import { TiHome } from "react-icons/ti";
import { VideoPageContext } from "components/utils/VideoPageContext";
import Controller from "components/elements/Controller";
import { RiPlayCircleFill, RiPauseCircleFill } from "react-icons/ri";
import { isPublished } from "components/utils/InternalRouter";
import UserPreferences from "components/utils/UserPreferences";

const VideoLeftArea = (props: any) => {
	const [autoPlay, setAutoPlay] = useState<boolean>(UserPreferences.getAutoPlay());
	const {
		chapterVideo,
		indexVideo,
		pageIndex,
		goToVideo
	} = useContext(VideoPageContext);

	return (
		<>
			<div className="video-left-area">
				<div className="video-left-area__thumbnails">
					<ImageComponent
						imageKey={chapterVideo?.imageChapter}
					/>
				</div>
				<p>{chapterVideo?.name}</p>
				<Controller className="left">
					<Arrow
						onClick={() => {
							props.PlayOrPause(true)
							goToVideo(pageIndex - 1);
						}}
						className={
							pageIndex > 0
								? "controller--prev-btn"
								: "controller--prev-btn controller--btn-disabled"
						}
					/>
					{!props.playing ? (
						<RiPlayCircleFill onClick={() => props.PlayOrPause()} className="controller--play-btn" />
					) : (
						<RiPauseCircleFill onClick={() => props.PlayOrPause()} className="controller--play-btn" />
					)}

					<Arrow
						onClick={() => {
							props.PlayOrPause(true)
							goToVideo(pageIndex + 1);
						}}
						className={
							indexVideo &&
								pageIndex < indexVideo.chapter.filter((p: any) => isPublished(p.publicationDate)).length - 1 &&
								chapterVideo?.status !== 'prochainement'
								? "controller--next-btn"
								: "controller--next-btn controller--btn-disabled"
						}
					/>
				</Controller>
			</div>
			<div className="ff-video-left-area-autoplay">
				<div className={`ff-video-left-area-autoplay-toggle ff-clickable ${autoPlay ? 'on' : 'off'}`} onClick={() => {
					UserPreferences.toggleAutoPlay();
					setAutoPlay(UserPreferences.getAutoPlay());
				}}>
					<Circle className="ff-video-left-area-autoplay-toggle-circle" />
					<Toggle className="ff-video-left-area-autoplay-toggle-background" />
				</div>
				<div className="ff-video-left-area-autoplay-text">Lecture automatique</div>
			</div>
			<div className="ff-video-left-area-buttons">
				<div onClick={props.fullScreen} className="ff-video-left-area-buttons__btn">
					<IconFullScreen className="icon-fullscreen" />
					<p>Plein Écran</p>
				</div>
				<div className="ff-video-left-area-buttons__btn">
					<IoMenu className="icon-chapters" />
					<Link to={`/${props.categoryName}/${indexVideo?.id}`}>{props.categoryName === "walad" ? "Épisodes" : "Chapitres"}</Link>
				</div>
				<div className="ff-video-left-area-buttons__btn">
					<TiHome className="icon-home" />
					<Link to={`/${props.categoryName}`}>Accueil</Link>
				</div>
			</div>
		</>
	);
};

export default VideoLeftArea;
