import "./App.scss";
import HomepageComponent from "./components/Homepage";
import ChapterComponent from "./components/V2/ChapterV2";
import VideoPlayComponent from "./components/V2/VideoPlayV2";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Secured from "Secured";
import VideoPageContextProvider from "components/utils/VideoPageContext";
import BlockerContextProvider from "components/utils/BlockerContext";
import UserContextProvider from "components/utils/UserContext";
import { AmplifyAuthenticator, AmplifySignUp } from "@aws-amplify/ui-react";
import UserAnnouncement from "components/elements/common/phoneblocked/UserAnnouncement";
import MainContextProvider from "components/utils/MainContext";
import VideoContextProvider from "components/utils/VideoContext";

const FAMILLE_FOULANE_CATEGORY = 'famille-foulane';
const WALAD_CATEGORY = 'walad';

const signUpFields = [
	{
		type: "name",
		label: "Name",
		placeholder: "Enter your name",
		hint: null,
		required: true,
	},
	{
		type: "email",
		label: "Email Address *",
		required: true,
	},
	{
		type: "password",
		label: "Password *",
		required: true,
	},
];

function App() {
	return (<>
		<UserAnnouncement />
		<UserContextProvider>
			<BlockerContextProvider>
				<VideoContextProvider>
					<MainContextProvider>
						<Secured>
							<Router>
								<Switch>
									<Route path="/" exact render={(props) => Homepage(props, FAMILLE_FOULANE_CATEGORY)} />
									<Route path="/famille-foulane" exact render={(props) => Homepage(props, FAMILLE_FOULANE_CATEGORY)} />
									<Route path="/famille-foulane/:videoId" exact render={(props) => Chapter(props, FAMILLE_FOULANE_CATEGORY)} />
									<Route path="/famille-foulane/:videoId/:chapterIndex" exact render={(props) => VideoPlay(props, FAMILLE_FOULANE_CATEGORY)} />
									<Route path="/walad" exact render={(props) => Homepage(props, WALAD_CATEGORY)} />
									<Route path="/walad/:videoId" exact render={(props) => Chapter(props, WALAD_CATEGORY)} />
									<Route path="/walad/:videoId/:chapterIndex" exact render={(props) => VideoPlay(props, WALAD_CATEGORY)} />
									{process.env.REACT_APP_DOMAIN_NAME === 'localhost' && (
										<Route path="/login" exact render={Login} />
									)}
								</Switch>
							</Router>
						</Secured>
					</MainContextProvider>
				</VideoContextProvider>
			</BlockerContextProvider>
		</UserContextProvider>
	</>);
}

export default App;

const Homepage = (props: any, categoryName: string) => <HomepageComponent {...props} categoryName={categoryName} />;

const Chapter = (props: any, categoryName: string) => <ChapterComponent {...props} categoryName={categoryName} />;

const VideoPlay = (props: any, categoryName: string) => (
	<VideoPageContextProvider {...props} categoryName={categoryName}>
		<VideoPlayComponent {...props} categoryName={categoryName} />
	</VideoPageContextProvider>
);

const Login = () => (
	<AmplifyAuthenticator usernameAlias="email">
		<AmplifySignUp
			usernameAlias="email"
			formFields={signUpFields}
			slot="sign-up"
		/>
	</AmplifyAuthenticator>
);
