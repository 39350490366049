import "./HomeLeftArea.scss";
import { ReactComponent as FoulaneIcon } from "assets/v2/ff-app-name.svg";
import { ReactComponent as WaladIcon } from "assets/walad/walad-hp-icon.svg";

import HooPowAppMenuV2 from "components/elements/common/internalrouter/HooPowAppMenuV2";
import { useContext } from "react";
import { UserContext } from "components/utils/UserContext";
import FreemiumAvailable from "components/elements/FreemiumAvailable";

const categoryIcon = (categoryName?: string) => {
	if (categoryName === 'walad') {
		return <WaladIcon />;
	} else {
		return <FoulaneIcon />;
	}

}

const HomeLeftArea = (props: { categoryName?: string }) => {
	const { freemiumEnabled, isUserAuthenticated } = useContext(UserContext);
	const { categoryName } = props;

	return (
		<>
			{freemiumEnabled && !isUserAuthenticated && isUserAuthenticated !== undefined && (
				<FreemiumAvailable />
			)}
			<div className="ff-home-icon"> {categoryIcon(categoryName)}</div>
			<HooPowAppMenuV2 />
		</>
	);
};

export default HomeLeftArea;
