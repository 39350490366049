import BaseDS from "datastore/BaseDS";
import { createContext, useEffect, useState } from "react";

export const VideoContext = createContext<any>("");

const VideoContextProvider = (props: any) => {
	const [allVideo, setAllVideo] = useState<any[]>([]);

	useEffect(() => {
		const iswalad = document.location.href.indexOf("/walad") > -1;
		BaseDS.getVideosByCategoryName(iswalad ? "walad" : "famille-foulane").then((data: any) => {
			data.sort((a: any, b: any) => new Date(b.publicationDate).getTime() - new Date(a.publicationDate).getTime())
			setAllVideo(data);
		});
	}, []);

	return (
		<VideoContext.Provider value={{ allVideo, setAllVideo }} >
			{props.children}
		</VideoContext.Provider>
	);
};

export default VideoContextProvider;
