import "./VideoPlayV2.scss";
import { useEffect, useRef, useContext, useState } from "react";
import VideoComponentV2 from "components/elements/VideoComponentV2";
import LayoutV2 from "components/layout/LayoutV2";
import VideoLeftArea from "components/layout/VideoLeftArea";
import BaseDS from "datastore/BaseDS";
import { VideoPageContext } from "components/utils/VideoPageContext";
import UserPreferences from "components/utils/UserPreferences";
import { ReactComponent as NextBtn } from "../../assets/v2/next-btn.svg";
import { ReactComponent as PreviousBtn } from "../../assets/v2/previous-btn.svg";
import { ReactComponent as ReplayBtn } from "../../assets/v2/replay-btn.svg";
import { isPublished } from "components/utils/InternalRouter";
import { UserContext } from "components/utils/UserContext";
import { BlockerContext } from "components/utils/BlockerContext";

const closeFullscreen = (docEl: any) => {
	if (!docEl.fullscreenElement && !docEl.mozFullScreenElement &&
		!docEl.webkitFullscreenElement && !docEl.msFullscreenElement) {
		console.log('Not full screen !!!')
	} else {
		if (docEl.exitFullscreen) {
			docEl.exitFullscreen();
		} else if (docEl.webkitExitFullscreen) { /* Safari */
			docEl.webkitExitFullscreen();
		} else if (docEl.msExitFullscreen) { /* IE11 */
			docEl.msExitFullscreen();
		}
	}
}

const VideoPlayV2 = (props: any) => {
	const [playing, setPlaying] = useState<boolean>(false);
	const chapterIndex: number = parseInt(props.match.params.chapterIndex);
	const [loaded, setLoaded] = useState<boolean>(false);
	const [displayBtn, setDisplayBtn] = useState<boolean>(false);
	const { isUserAuthenticated } = useContext(UserContext);
	const { setBlock } = useContext(BlockerContext);
	const chapterKeyRef = useRef<number>(0);
	const documentRef = useRef<any>();

	useEffect(() => {
		if (!documentRef.current) {
			documentRef.current = document;
		}
	}, []);

	const {
		chapterVideo,
		goToVideo,
		setIndexVideo,
		indexVideo,
		currentIndexVideoId,
		pageIndex
	} = useContext(VideoPageContext);
	const controlRef = useRef<any>(null);

	useEffect(() => {
		BaseDS.getFullVideoById(currentIndexVideoId).then(setIndexVideo);
	}, [currentIndexVideoId, setIndexVideo]);

	useEffect(() => {
		if (!loaded && indexVideo) {
			goToVideo(chapterIndex - 1);
			setLoaded(true);
		}
	}, [chapterIndex, goToVideo, loaded, indexVideo]);

	const PlayOrPause = (forceStop: boolean) => {
		controlRef.current?.isPaused((isPaused: boolean) => {
			if (isPaused && !forceStop) {
				controlRef.current?.play();
				setPlaying(true);
			} else {
				controlRef.current?.pause();
				setPlaying(false);
			}
		});
	};

	const onPlay = () => {
		setDisplayBtn(false);
		setPlaying(true);
	};

	const onPause = () => {
		setPlaying(false);
	};

	const onEnded = () => {
		if (UserPreferences.getAutoPlay() && chapterKeyRef.current < indexVideo.chapter.filter((p: any) => isPublished(p.publicationDate)).length - 1) {
			goToVideo(chapterKeyRef.current + 1);
		} else {
			controlRef.current?.pause();
			setPlaying(false);
			setDisplayBtn(true);
			closeFullscreen(documentRef.current);
		}
	};

	useEffect(() => {
		chapterKeyRef.current = pageIndex;
	}, [pageIndex]);

	useEffect(() => {
		if (playing && !isUserAuthenticated && chapterKeyRef.current > 2) {
			setDisplayBtn(false);
			setPlaying(false);
			controlRef.current?.pause()
			setBlock(true);
		}
	}, [playing, isUserAuthenticated, setBlock]);

	const fullScreen = () => {
		controlRef.current.fullScreen();
	};

	return (
		<LayoutV2
			leftArea={<VideoLeftArea playing={playing} PlayOrPause={PlayOrPause} setPlaying={setPlaying} fullScreen={fullScreen} {...props} />}
			isAuthenticated={true}
			className={props.categoryName}
		>
			<div className="ff-video-player-background"></div>
			<h2 className="ff-video-player-name">{chapterVideo?.name}</h2>
			<VideoComponentV2
				className="ff-video-player"
				onPlay={onPlay}
				onPause={onPause}
				onEnded={onEnded}
				pageIndex={pageIndex}
				ref={controlRef}
				videoKey={chapterVideo?.vimeoKey}
			/>
			{displayBtn && (
				<div className="ff-video-player-btn">
					<ReplayBtn className="ff-clickable" onClick={() => {
						setDisplayBtn(false);
						controlRef.current?.play()
					}} />
					{pageIndex < indexVideo.chapter.filter((p: any) => isPublished(p.publicationDate)).length - 1 && (
						<NextBtn className="ff-clickable" onClick={() => {
							setDisplayBtn(false);
							goToVideo(pageIndex + 1)
						}} />
					)}
					{pageIndex > 0 && (
						<PreviousBtn className='ff-clickable' onClick={() => {
							setDisplayBtn(false);
							goToVideo(pageIndex - 1)
						}} />
					)}
				</div>
			)}
		</LayoutV2>
	);
};

export default VideoPlayV2;
