import "./HomeLeftArea.scss";
import { ReactComponent as FoulaneIcon } from "assets/v2/ff-app-name.svg";
import { ReactComponent as WaladIcon } from "assets/walad/walad-hp-icon.svg";
import HooPowAppMenuV3 from "components/elements/common/internalrouter/HooPowAppMenuV3";

const categoryIcon = (categoryName?: string) => {
	if (categoryName === 'walad') {
		return <WaladIcon />;
	} else {
		return <FoulaneIcon />;
	}
}

const HomeLeftAreaV3 = (props: { categoryName?: string }) => {
	const { categoryName } = props;

	return (
		<>
			<div className="ms-home-icon-v3"> {categoryIcon(categoryName)} </div>
			<HooPowAppMenuV3 />
		</>
	);
};

export default HomeLeftAreaV3;
