import ApiWrapper from './ApiWrapper';
import { TVideo, TChapter } from '../types'

const apiName = 'ApiGatewayRestApi';

const categoryNameToMainId: any = {
  'famille-foulane': '1617086158923',
  'walad': '1633419189106'
}

export default class BaseDS {

  static getVideosByCategoryName = async (categoryName: string): Promise<TVideo[]> => {
    return ApiWrapper.get(apiName, '/video/getChildrenByParentId/' + categoryNameToMainId[categoryName]);
  };

  static getVideoById = async (videoId: string): Promise<TVideo> => {
    return ApiWrapper.get(apiName, `/video/getById/${videoId}`);
  };

  static getFullVideoById = async (videoId: string): Promise<TVideo> => {
    return ApiWrapper.get(apiName, `/video/getFullObjectById/${videoId}`);
  };

  static getFullChapterById = async (videoId: string, sessionKey?: number): Promise<TChapter> => {
    return ApiWrapper.get(apiName, `/video/getFullObjectById/${videoId}-${sessionKey}`);
  };
}

