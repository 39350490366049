import { useContext, useEffect, useState } from 'react';
import { ReactComponent as HoopowLogo } from 'assets/hoopow-logo.svg';
import { ReactComponent as IconProfil } from './assets/icon-profil.svg';
import { ReactComponent as IconEditProfil } from './assets/icon-editprofil.svg';
import { ReactComponent as IconMyOrders } from './assets/icon-myorders.svg';
import { ReactComponent as IconMemberSpace } from './assets/icon-memberspace.svg';

import { ReactComponent as IconWho } from './assets/icon-who.svg';
import { ReactComponent as IconWhat } from './assets/icon-what.svg';
import { ReactComponent as IconSupport } from './assets/icon-support.svg';
import { ReactComponent as IconConfidentiality } from './assets/icon-confidentiality.svg';
import { ReactComponent as IconContactUs } from './assets/icon-contactus.svg';
import { ClipLoader } from 'react-spinners';

import { ReactComponent as IconGift } from './assets/icon-gift.svg';
import './MenuV3.scss';
import { Auth } from 'aws-amplify';
import { routeToPlatformHomePage } from 'components/utils/InternalRouter';
import { UserContext } from 'components/utils/UserContext';
import { BlockerContext, steps } from 'components/utils/BlockerContext';
import ImageDataStore from 'datastore/ImageDataStore';

function MenuV3(props: { menuOpen?: boolean, setBlock: Function }) {
	const { setBlock, setUnblockStep, nextStep } = useContext(BlockerContext);
	const [menuOpen, setMenuOpen] = useState<boolean | undefined>(props.menuOpen);
	const [userRoot, setRootUser] = useState<any>();

	const { userInfo, isUserAuthenticated, userSubscriptionDate } = useContext(UserContext);

	const logout = async () => {
		await Auth.signOut();
		window.location.reload();
	}

	useEffect(() => {
		if (userInfo && userInfo.attributes) {
			setRootUser({
				name: userInfo.attributes.name,
				email: userInfo.attributes.email,
				avatar: ImageDataStore.getUrlFileFromHoopow(userInfo.attributes['custom:avatarId'] || ('images/man_avatar-sample.svg'))
			});
		} else {
			setRootUser(undefined);
		}
	}, [userInfo]);

	const openPopup = (screenType: steps) => {
		nextStep.current = steps.RELOAD;
		setUnblockStep(screenType);
		setBlock(true);
	}

	const getFormattedDate = (epochTime: number) => {
		return new Date(epochTime * 1000).toLocaleDateString();
	}

	return (
		<>
			<div className={`hp-menuV3-header`}>
				{isUserAuthenticated !== undefined ? (<>
					<>
						{isUserAuthenticated ? (<>
							<div className={`hp-menu-nav-frame ks-clickable`} onClick={() => setMenuOpen(prev => !prev)} >
								<div className={`hp-menu-nav-icon ${menuOpen && 'open'}`} >
									<span></span>
									<span></span>
									<span></span>
									<span></span>
								</div>
							</div>
							<img src={userRoot?.avatar} className='hp-menu-icon-profil' alt="Avatar Utilisateur" />
						</>) : (
							<IconProfil className='hp-menu-icon-profil' />
						)}
						<div className='hp-menu-links'>
							<a href={`${routeToPlatformHomePage()}`} className="hp-menu-link-hoopow"><HoopowLogo className='hp-menu-logo' /></a>
							<div className='hp-menu-links-auth'>
								{!isUserAuthenticated ? (<>
									<button onClick={() => openPopup(steps.SIGNIN)}>se connecter</button><span>&nbsp;/&nbsp;</span>
									<button onClick={() => openPopup(steps.SIGNUP)}>s&apos;inscrire</button>
								</>) : (
									<span>{userSubscriptionDate || process.env.REACT_APP_FEATURE_PAUSE === '1'
										? userRoot?.name
										: (
											<>
												{userSubscriptionDate !== 0 ? (
													<button className='mainBtn' onClick={() => {
														setUnblockStep(steps.SUBSCRIPTION_PAYMENT);
														setBlock(true);
													}}>
														S&apos;ABONNER
													</button>
												) : (<div className='empty'></div>)}
											</>)}</span>
								)}
							</div>
						</div>
					</>
				</>) : (<><ClipLoader color="white" /></>)}
			</div>

			<div className={`hp-menu-frame hp-menuV3 ${menuOpen && 'open'}`}>
				<div className='hp-menu-frame-profil' >
					<div className='hp-menu-frame-profil-icon' onClick={() => openPopup(steps.EDITPROFIL)} >
						<IconEditProfil className='hp-menu-frame-profil-edit-icon' />
						<img src={userRoot?.avatar} alt="Avatar Utilisateur" />
					</div>
					<h3>{userRoot?.name}</h3>
					<span>{userSubscriptionDate ? "Abonné depuis le " + getFormattedDate(userSubscriptionDate) : "non abonné"}</span>
					<button className='hp-menu-frame-profil-logout' onClick={logout}>se déconnecter</button>
				</div>
				<div className='hp-menu-frame-profil-separator' ></div>
				<div className='hp-menu-frame-section' >
					<button onClick={() => openPopup(steps.MEMBER)} className='hp-menu-frame-icon-title'>
						<IconMemberSpace />
						<span>espace membre</span>
					</button>
					<button onClick={() => openPopup(steps.ORDERS)} className='hp-menu-frame-icon-title'>
						<IconMyOrders />
						<span>mes achats</span>
					</button>
					{process.env.REACT_APP_FEATURE_PAUSE !== '1' ? (<>
						<button onClick={() => openPopup(steps.GIFT)} className='hp-menu-frame-icon-title'>
							<IconGift />
							<span>code et carte cadeau</span>
						</button>
					</>) : (<></>)}
				</div>
				<div className='hp-menu-frame-profil-separator' ></div>
				<div className='hp-menu-frame-section' >
					<button onClick={() => openPopup(steps.WHO)} className='hp-menu-frame-icon-title'>
						<IconWho />
						<span>BDouin, c’est qui ?</span>
					</button>
					<button onClick={() => openPopup(steps.WHAT)} className='hp-menu-frame-icon-title'>
						<IconWhat />
						<span>Hoopow, c’est quoi ?</span>
					</button>
					<button onClick={() => openPopup(steps.SUPPORTUS)} className='hp-menu-frame-icon-title'>
						<IconSupport />
						<span>3 façons de nous soutenir</span>
					</button>
				</div>
				<div className='hp-menu-frame-profil-separator' ></div>
				<div className='hp-menu-frame-section' >
					<button onClick={() => document.location.href = routeToPlatformHomePage() + '/cguconnected'} className='hp-menu-frame-icon-title'>
						<IconConfidentiality />
						<span>Confidentialité</span>
					</button>
					<button onClick={() => openPopup(steps.CONTACTUS)} className='hp-menu-frame-icon-title'>
						<IconContactUs />
						<span>Nous contacter</span>
					</button>
				</div>
			</div>
		</>
	);
}

export default MenuV3;
