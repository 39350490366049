import "./ChapterV2.scss";
import { useState, useEffect, useContext } from "react";
import LayoutV2 from "components/layout/LayoutV2";
import ChapterLeftArea from "../layout/ChapterLeftArea";
import BaseDS from "datastore/BaseDS";
import { TChapter } from "types";
import { ReactComponent as WhitePlay } from "../../assets/v2/white-play.svg";
import { ReactComponent as PlayMobile } from "../../assets/v2/ff-play-mobile.svg"
import { ReactComponent as WaladPlaceholder } from "../../assets/walad/walad-placeholder.svg";
import { Link } from "react-router-dom";
import ImageComponent from "components/elements/ImageComponent";
import InnerHtml from "components/elements/InnerHtml";
import { isPublished } from "components/utils/InternalRouter";
import { UserContext } from "components/utils/UserContext";
import { BlockerContext } from "components/utils/BlockerContext";
import { ReactComponent as IconLock } from "../../assets/v3/icon-lock.svg";
import { VideoContext } from "components/utils/VideoContext";

const familleFoulaneKey = "famille-foulane"

const ChapterV2 = (props: any) => {
	const currentVideoId = props.match.params.videoId;
	const { allVideo } = useContext(VideoContext);
	const { userHasAccess } = useContext(UserContext);
	const { setBlock, setItemId, setAllData, setItemChapterId, setAppType, setCurrentVideoData } = useContext(BlockerContext);
	const [allChapter, setAllChapter] = useState<TChapter[]>([]);
	const { categoryName } = props;

	useEffect(() => {
		BaseDS.getFullVideoById(currentVideoId).then((data: any) => {
			setAllChapter(data.chapter);
		});
	}, [currentVideoId]);

	const protectedDataDef = (chaptedId: string) => {
		if (categoryName === familleFoulaneKey) {
			return {
				itemId: currentVideoId,
				listOfData: allVideo
			}
		} else {
			return {
				itemId: chaptedId,
				listOfData: allChapter
			}
		}
	}

	const numberOfFreeChapter = categoryName === familleFoulaneKey ? 3 : 1;
	const isBlocked = (chapter: any, index: number) => {
		return isPublished(chapter.publicationDate) && !userHasAccess(protectedDataDef(chapter.id).itemId) && !userHasAccess(chapter.id) && index > (numberOfFreeChapter - 1)
	}

	return (
		<LayoutV2 leftArea={<ChapterLeftArea {...props} />} isAuthenticated={true} className={categoryName}>
			<div className="chapter-list">
				{allChapter.map((chapter, index) => {
					return (
						<Link
							className={`chapter-list__chapter ${isPublished(chapter.publicationDate) || 'soon'} ${isBlocked(chapter, index) && 'chapter-list__chapter-blocked'}`}
							key={index}
							to={`/${categoryName}/${currentVideoId}/${chapter.chapterKey}`}
							onClick={(event: any) => {
								if (!userHasAccess(protectedDataDef(chapter.id).itemId) && !userHasAccess(chapter.id) && index > (numberOfFreeChapter - 1)) {
									event.preventDefault();
									const protectedData = protectedDataDef(chapter.id);
									setItemId(protectedData.itemId);
									setAllData(protectedData.listOfData);
									setItemChapterId(chapter.id);
									setBlock(true);
									setAppType(categoryName);
									setCurrentVideoData(chapter);
									return false;
								}
							}} >

							{categoryName === 'famille-foulane' && <IconLock className="lock" />}
							<div className={`chapter-list__chapter__img-wrapper`}>
								{isPublished(chapter.publicationDate) || chapter.imageChapter ? (<>
									{categoryName === 'walad' && <IconLock className="lock" />}
									<div className="overlay"></div>
									<ImageComponent className="chapter-list__chapter__img-wrapper--thumbnails" imageKey={chapter.imageChapter} />
								</>) : (<>
									{categoryName === 'walad' ? (<>
										<WaladPlaceholder className="chapter-list__chapter__img-wrapper--thumbnails" />
									</>) : (
										<img className="chapter-list__chapter__img-wrapper--thumbnails"
											src={`/foulane_30defis_prochainement.jpg`}
											alt={'Prochainement Chapitre ' + (index + 1)}
										/>
									)}
								</>)}
								<PlayMobile className="play-mobile" />
							</div>
							<div className="chapter-list__infos">
								<h2>{chapter.name}</h2>
								<InnerHtml text={isPublished(chapter.publicationDate) ? chapter.description : "PROCHAINEMENT"} className={`${!isPublished(chapter.publicationDate) && 'soonTitle'}`} />
								<div className="chapter-list__play-time">
									<WhitePlay className="play-chapter" />
									<span className="time">{chapter.duration}</span>
								</div>
							</div>
							<div className="overlay"></div>
						</Link>
					);
				})}
			</div>
		</LayoutV2>
	);
};

export default ChapterV2;
