import { createContext, useState, useCallback, useContext } from "react";
import { useHistory } from "react-router-dom";
import { TChapter, TVideo } from "types";
import { BlockerContext } from "./BlockerContext";
import { isPublished } from "./InternalRouter";
import { UserContext } from "./UserContext";


export const VideoPageContext = createContext<any>("");

const VideoPageContextProvider = (props: any) => {
	const history = useHistory();
	const { userHasAccess } = useContext(UserContext);
	const { setBlock, setItemId, setAllData } = useContext(BlockerContext);
	const currentIndexVideoId = props.match.params.videoId;
	const [chapterVideo, setChapterVideo] = useState<TChapter>();
	const [indexVideo, setIndexVideo] = useState<TVideo>();
	const [pageIndex, setPageIndex] = useState<number>(0);

	const updatePageUrl = useCallback(
		(index: number) => {
			if (index >= 0) {
				history.push(`/${props.categoryName}/${currentIndexVideoId}/${index + 1}`);
			}
		},
		[currentIndexVideoId, history, props.categoryName]
	);

	const goToVideo = useCallback((chapterIndex: number) => {
		if (indexVideo && indexVideo.chapter && indexVideo.chapter.filter((p: TChapter) => isPublished(p.publicationDate)).length) {
			if (chapterIndex > -1 && chapterIndex < indexVideo.chapter.filter((p: TChapter) => isPublished(p.publicationDate)).length) {
				if (userHasAccess(indexVideo.id) || userHasAccess(indexVideo.chapter[chapterIndex].id) || (props.categoryName === 'walad' && chapterIndex === 0) || (props.categoryName === 'famille-foulane' && chapterIndex < 3)) {
					setPageIndex(chapterIndex);
					setChapterVideo(indexVideo.chapter[chapterIndex]);
					history.push(`/${props.categoryName}/${currentIndexVideoId}/${chapterIndex + 1}`);
				} else {
					setItemId(indexVideo.chapter[chapterIndex].id);
					setAllData(indexVideo.chapter);
					setBlock(true);
				}
			} else {
				goToVideo(0);
			}
		}
	}, [currentIndexVideoId, history, indexVideo, props.categoryName]);

	const previousVideo = () => {
		if (pageIndex - 1 >= 0 && indexVideo) {
			setPageIndex(pageIndex - 1);
			setChapterVideo(indexVideo.chapter[pageIndex - 1]);
			updatePageUrl(pageIndex - 1);
		}
	};

	return (
		<VideoPageContext.Provider value={{ history, chapterVideo, setChapterVideo, indexVideo, setIndexVideo, pageIndex, setPageIndex, previousVideo, goToVideo, updatePageUrl, currentIndexVideoId }} >
			{props.children}
		</VideoPageContext.Provider>
	)
}

export default VideoPageContextProvider
