import "./HomepageV2.scss";
import { useContext, useRef } from "react";
import ImageComponent from "components/elements/ImageComponent";
import { RiPlayCircleFill } from "react-icons/ri";
import { ReactComponent as IndexIcon } from "../../assets/v2/ff-index-icon.svg"
import { Link } from "react-router-dom";
import InnerHtml from "components/elements/InnerHtml";
import SVG from "components/elements/SVG";
import { TVideo } from "types";
import { VideoContext } from "components/utils/VideoContext";
import { ReactComponent as NavArrow } from 'assets/v3/nav-arrow.svg';
import { ReactComponent as NavArrowRight } from 'assets/v3/nav-arrow-right.svg';

function HomepageV2(props: { categoryName: string }) {
	const { allVideo } = useContext(VideoContext);
	const { categoryName } = props;
  const scrollableDivRef = useRef<any>();

	const calculateLoaderSize = (optimalSize: number) => {
    const screenDiv = document.getElementById('mainContentScreen');
    return screenDiv ? optimalSize * screenDiv.clientWidth / 1039 : optimalSize;
  }

  const scrollLeft = () => {
    scrollableDivRef.current.scroll({
      left: scrollableDivRef.current.scrollLeft - calculateLoaderSize(750),
      behavior: 'smooth'
    });
  };

  const scrollRight = () => {
    scrollableDivRef.current.scroll({
      left: scrollableDivRef.current.scrollLeft + calculateLoaderSize(750),
      behavior: 'smooth'
    });
  };

	return (<>
		{allVideo?.length > 2 && (<>
			<NavArrow className="ff-homepage-nav-left ks-clickable" onClick={scrollLeft} />
			<NavArrowRight className="ff-homepage-nav-right ks-clickable" onClick={scrollRight} />
		</>)}
		<div ref={scrollableDivRef} className={`ff-homepage-list ${allVideo?.length < 3 && 'ff-homepage-centered'}`}>
			{allVideo.map((movie: TVideo, index: number) => (
				<Link
					to={`/${categoryName}/${movie.id}`}
					key={index}
					className={
						movie.status !== 'prochainement'
							? "ff-homepage-list__movie"
							: "ff-homepage-list__movie movie-soon"
					}
				>
					<div className="ff-homepage-list__movie__cover">
						<ImageComponent
							className="ff-homepage-list__movie__cover--poster"
							imageKey={movie.posterVideo}
						/>
						{movie.status !== 'prochainement' && allVideo?.length < 3 && <RiPlayCircleFill className="icon-play-movie" />}
					</div>
					{movie.status !== 'prochainement' ? (
						<div className="ff-homepage-list__movie--infos">
							<div className="ff-homepage-list__movie--infos-thumbnail">
								<SVG src={movie.thumbnailsImage} />
							</div>
							<div>
								<h2>{movie.name}</h2>
								<div style={{ display: "flex" }}>
									<p className={movie.status === 'complet' ? "status complete" : "status ongoing "}>
										{movie.status}
									</p>
									<span className="time">{movie.durationTotal}</span>
								</div>
							</div>
						</div>
					) : (
						<div className="ff-homepage-list__movie--soon">
							<h2> {movie.name} </h2>
							<span>{categoryName === 'walad' ? 'RENTRÉE 2022' : 'Prochainement'}</span>
						</div>
					)}
					{movie.status !== 'prochainement' && (
						<>
							{" "}
							<InnerHtml text={movie.description} className="ff-homepage-list__movie--desc" />
							<div className="ff-homepage-list__movie--icons">
								<Link to={`/${categoryName}/${movie.id}/${index + 1}`} className="icon-lecture">
									<RiPlayCircleFill />
									<p>Lecture</p>
								</Link>
								<Link className="icon-index" to={`/${categoryName}/${movie.id}`}>
									<IndexIcon />
									<p>Index</p>
								</Link>
							</div>
							<div className="ff-homepage-list__movie__authors">
								<p>
									Scénario : <span>{movie.textAuthor}</span>
								</p>
								<p>
									Dessin : <span>{movie.drawAuthor}</span>
								</p>
								<p>
									Voix : <span>{movie.voiceAuthor}</span>
								</p>
							</div>{" "}
						</>
					)}
				</Link>
			))}
		</div>
	</>);
}

export default HomepageV2;
