import {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from "react";

import ImageDataStore from "datastore/ImageDataStore";
import Vimeo from "@vimeo/player";

const VideoComponent = forwardRef((props: any, ref: any) => {
	const [imageURL, setImageURL] = useState();
	const [started, setStarted] = useState<boolean>(false);
	const [player, setPlayer] = useState<Vimeo>()
	const videoRef = useRef<any>();
	const { onPlay, onPause, onEnded } = props;

	useEffect(() => {
		if (props.imageKey) {
			ImageDataStore.getUrlImage(props.imageKey, setImageURL);
		}
	}, [props.imageKey]);

	useEffect(() => {
		if (videoRef.current && !player && props.videoKey) {
			const vimeoPlayer: Vimeo = new Vimeo(videoRef.current, { id: props.videoKey, playsinline: false });
			vimeoPlayer.on('play', onPlay);
			vimeoPlayer.on('pause', onPause);
			vimeoPlayer?.on('ended', onEnded);
			setPlayer(vimeoPlayer);
		}
	}, [videoRef, onPlay, onPause, player, props.videoKey, onEnded]);

	useEffect(() => {
		if (player && props.videoKey) {
			player?.loadVideo(props.videoKey).then(() => player.play()).catch(() => player.play());
		}
	}, [player, props.videoKey]);

	useImperativeHandle(ref, () => ({
		play() {
			player?.play()
			setStarted(true);
		},
		pause() {
			player?.pause();
		},
		isPaused(callback: Function) {
			player?.getPaused().then((isPaused: boolean) => callback(isPaused))
		},
		hasStarted() {
			return started;
		},
		goTo(time: number) {
			player?.setCurrentTime(time);
			this.play();
		},
		fullScreen() {
			const docEl: any = videoRef.current;

			if (docEl) {
				const requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
				if (requestFullScreen) requestFullScreen.call(docEl)
			}
		}
	}));

	return (
		<>
			{imageURL && (
				<img src={imageURL} alt={props.imageKey} style={{ display: (started ? 'none' : 'block') }} />
			)}
			{props.videoKey && (
				<div ref={videoRef} className={props.className} ></div>
			)}
		</>
	);
}
);

export default VideoComponent;
