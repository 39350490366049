import { API, Auth } from 'aws-amplify';

const localUserPrefix = "cognito-idp.eu-west-1.amazonaws.com/eu-west-1_z1Xt9rPHs,cognito-idp.eu-west-1.amazonaws.com/eu-west-1_z1Xt9rPHs:CognitoSignIn:";

async function getOptions(initialOptions: any) {
  const finalOptions = { ...initialOptions };
  if (!finalOptions['headers']) finalOptions['headers'] = {};

  const urlBase: string = process.env.REACT_APP_API_ENPOINT_BASE_URL || '';
  console.log('urlBase', urlBase);
  if (urlBase.indexOf('localhost') > -1 || urlBase.indexOf('192.') > -1) {
    try {
      const userInfo = await Auth.currentUserInfo();
      finalOptions['headers']['cognitoAuthenticationType'] = 'authenticated';
      finalOptions['headers']['cognitoAuthenticationProvider'] = localUserPrefix + userInfo.attributes.sub;
    } catch {
      finalOptions['headers']['cognitoAuthenticationType'] = 'unauthenticated';
    }
  }

  return finalOptions;
}

export default class ApiWrapper {

  static get = async (apiName: string, path: string, options?: any) => {
    return API.get(apiName, path, await getOptions(options));
  };

  static put = async (apiName: string, path: string, options: any) => {
    return API.put(apiName, path, await getOptions(options));
  };

  static post = async (apiName: string, path: string, options: any) => {
    return API.post(apiName, path, await getOptions(options));
  };

  static delete = async (apiName: string, path: string, options: any) => {
    return API.del(apiName, path, await getOptions(options));
  };

}