import { MouseEventHandler, useEffect, useRef } from 'react';
import ImageDataStore from '../../datastore/ImageDataStore';
import ImageLoadEvent from '../utils/ImageLoadEvent';

type SVGProps = {
  src: string | null | undefined,
  afterInjection?: any,
  className?: string,
  preload?: boolean,
  style?: any,
  onClick?: MouseEventHandler<any>
}

const blobToText = (b: Blob, callback: Function) => {
  const reader = new FileReader();
  reader.onload = function () {
    callback(reader.result);
  }
  reader.readAsText(b);
}

function SVG(props: SVGProps) {
  const svgRef = useRef<any>();
  const { src, afterInjection, preload } = props;
  useEffect(() => {
    if (src) {
      ImageLoadEvent.triggerImageToLoad(src);
      ImageDataStore.getImage(src, (url: any, err: any) => {
        if (!err && svgRef.current && !preload) {
          blobToText(url.Body, (text: string) => {
            svgRef.current.innerHTML = text;
            if (afterInjection) afterInjection(null, svgRef.current);
          })
        }
        setTimeout(() => ImageLoadEvent.triggerImageLoaded(src), 500);
      })
    }
  }, [src, afterInjection, preload]);
  return (
    <div onClick={props.onClick} className={props.className ? props.className : ''} style={props.style} >
      <div ref={svgRef} />
    </div>
  );
}

export default SVG;
