const localStorage: Storage = window.localStorage;

export default class UserPreferences {

  static toggleAutoPlay = () => {
    const autoPlay = localStorage.getItem('autoPlay');
    UserPreferences.setAutoPlay(autoPlay === 'on' ? 'off' : 'on');
  }

  private static setAutoPlay = (autoPlay: string) => {
    localStorage.setItem('autoPlay', autoPlay)
  }

  static getAutoPlay = () => {
    return localStorage.getItem('autoPlay') === 'on';
  }

}